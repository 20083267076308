@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.cards-st {
  padding: 30px 7% 50px;
  overflow-x: hidden;
  width: 100%;
}
.cards-st a {
  text-decoration: none;
}
button {
  background-color: transparent;
  border: none;
}
.center-content {
  margin: 0 7%;
}
@media (max-width: 1024px) {
  .center-content {
    margin: 0 4%;
  }
}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #007f92;
}
.thesame-margin {
  margin: 0px 7%;
}
.ant-table-cell {
  background-color: #fff !important;
}
.ant-form-item-control-input-content {
  border-radius: 5px;
  border: 1px solid #dadada;
  background: #f7f7f7;
}
.clicked-button {
  background-color: #00a8c0;
  color: #ffffff;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menues {
  margin-left: 50px;
  gap: 20px;
}
.menues a {
  color: #7e8299;
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
}
.menues .active-nav {
  color: #00a8c0;
  font-weight: 600;
}
.nav-drop-down .active-nav {
  color: #00a8c0;
  font-weight: 600;
}
.right-menue {
  gap: 10px;
  justify-content: end;
}
.bars {
  gap: 3px;
  width: 20px;
  display: none;
  margin-left: 12px;
}
.mobile-menues {
  display: none;
}
.bars span {
  border-radius: 100px;
  border: 2px solid black;
}
.nav-icons {
  display: flex;
  align-items: center;
  gap: 13px;
}
@media (max-width: 1180px) {
  .right-menue {
    width: 100%;
    justify-content: space-between;
  }
  .nav-bar {
    justify-content: space-between;
    width: 100%;
  }
  .menues {
    display: none;
  }
  .bars {
    display: grid;
  }
  .mobile-menues {
    z-index: 200;
    width: 100%;
    padding: 5px 20px;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: absolute;
    top: 110px;
    left: 0;
    transition: 300ms ease-in;
    z-index: 99999;
    display: grid;
    gap: 20px;
    padding: 30px 20px;
  }
  .mobile-menues a {
    color: #7e8299;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.01em;
  }
  .mobile-menues .active-nav {
    color: #00a8c0;
    font-weight: 600;
  }
  .profile-popup {
    top: 110px !important;
  }
} /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-input-out-of-range,
.ant-input-out-of-range input,
.ant-input-out-of-range textarea,
.ant-input-out-of-range .ant-input-show-count-suffix,
.ant-input-out-of-range .ant-input-data-count {
  color: #000000 !important;
}

.ant-timeline-item-content,
.ant-timeline-item-label {
  font-size: 16px !important;
  font-family: "Montserrat";
}
@media (max-width: 674px) {
  .ant-timeline-item-content,
  .ant-timeline-item-label {
    padding-right: 6px !important;
    font-size: 13px !important;
    margin-left: 0px;
  }
}
@media (max-width: 362px) {
  .ant-timeline-item-content,
  .ant-timeline-item-label {
    font-size: 12px !important;
  }
}
.ant-timeline-item-head .ant-timeline-item-head-blue {
  width: 20px;
  height: 20px;
}
.ant-form-item {
  margin-bottom: 0;
}
.ant-pagination {
  display: flex;
  justify-content: center !important;
  margin-top: 40px !important;
}
.ant-pagination li {
  border: 1px solid #00a8c0 !important;
  color: #1e1e1e;
  font-weight: 300;
  font-family: Montserrat;
  line-height: 17.07px;
  width: 48px;
  position: relative;
}
.ant-pagination-item-active {
  background-color: #00a8c0 !important;
}
.ant-pagination-item-active a {
  border-color: #00a8c0 !important;
  color: white !important;
}

.ant-pagination-item a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ant-pagination-next,
.ant-pagination-prev {
  border: 1px solid #bcbcbc !important;
}
.ant-timeline-item-content,
.ant-timeline-item-label {
  font-size: 13px;
  font-weight: 500;
  font-family: "Montserrat";
  color: #1e1e1e;
  line-height: 16px;
}
.ant-timeline .ant-timeline-item-head {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  border-width: 4px;
  position: relative;
}
.ant-timeline .ant-timeline-item {
  padding-bottom: 50px;
  display: flex;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  padding-right: 4px;
  padding-top: 2px;
  inset-block-start: 0;
}
.ant-timeline .ant-timeline-item-content {
  inset-block-start: 0;
  text-transform: capitalize;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-inline-start: -8px;
}
.ant-timeline {
  padding-top: 30px;
  height: 503px;
}
.cat-btn {
  justify-content: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  width: 100%;
  height: 54px;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  color: #1e1e1e;
  text-align: center;
  background-color: white;
  cursor: pointer;
  transition: 300ms;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.active-btn {
  background-color: #00a8c0;
  color: white;
}
.profile-popup {
  position: absolute;
  right: 60px;
  top: 78px;
  width: 200px;
  height: auto;
  padding: 25px 24px 25px 24px;
  list-style: none;
  display: grid;
  gap: 26px;
  background: #f5f5f5;
  border-radius: 10px;
  z-index: 1100;
}
.profile-popup li {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #1e1e1e;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  cursor: pointer;
}
.nav-drop-down {
  position: absolute;
  width: 200px;
  top: 60px;
  height: auto;
  padding: 0 24px;
  display: grid;
  background: #ffffff;
  border-radius: 10px;
  z-index: 1100;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.nav-drop-down a:first-child {
  padding-top: 26px;
}
.nav-drop-down a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-bottom: 26px;
  line-height: 14px;
  cursor: pointer;
  color: #7e8299;
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
}
.tracking-cards {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: start;
  background-color: #eff4f8;
  padding: 30px 45px 21px 39px;
  border-radius: 6px;
}
.tracking-cards h3 {
  color: #181c32;
}
.tracking-cards p {
  color: #a1a5b7;
}
@media (max-width: 600px) {
  .tracking-cards {
    padding: 30px 0 21px 0;
    margin: 0 !important;
    justify-content: center;
  }
  .tracking-cards div {
    width: 100%;
  }
}
.items-par {
  width: 100%;
}
@media (max-width: 1114px) {
  .items-par {
    overflow-x: scroll;
  }
  .tracking-cards {
    margin: 0 4%;
  }
}
.ant-collapse {
  border: none;
  background-color: transparent;
  margin-bottom: -10px;
}
.ant-collapse-item {
  border: 0 !important;
  border-bottom: 1px solid #dcdcdc !important;
  margin-top: 50px;
  border-radius: 0 !important;
}
.ant-collapse-header {
  border-radius: 0 !important;
}
.ant-collapse-header-text {
  color: #08090b;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.03em;
  margin-bottom: 50px;
}
.ant-table-cell {
  border: none !important;
}
.cart-item {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.03em;
  color: #08090b;
  height: fit-content !important;
  display: flex;
  align-items: center;
  gap: 10px;
}
.warehouse-table {
  border: 1px solid #efefef;
  margin: 32px 0px 60px;
  width: 85%;
  position: absolute;
  left: 46%;
  transform: translateX(-50%);
}
.center-table {
  position: relative;
  width: 100%;
}
.center-table .ant-table-row {
  font-family: Montserrat;
  font-size: 14px;
  color: #7e8299;
  line-height: 14px;
  font-weight: 600;
}
.center-table .ant-table-thead tr th {
  font-family: Montserrat;
  font-size: 12px;
  color: #a1a5b7 !important;
  font-weight: 600;
}
.center-table .ant-table-row td:nth-child(2) {
  color: #000000;
}
.center-table .ant-table-row td:nth-child(7) {
  padding-right: 0 !important;
}
.center-table .ant-table-row:last-child td {
  border: 0 !important;
}
.center-table .highlight-bottom-border > td {
  border-bottom: solid 1px #efefef !important;
}

.center-table .highlight-top-border > td {
  border-top: solid 2px #efefef !important;
}
@media (min-width: 1024px) {
  .center-table .ant-table-thead tr th:nth-child(7) {
    width: 120px;
  }
}
.center-table .ant-table-cell {
  overflow: hidden;
  overflow-wrap: normal !important;
}
.center-table .ant-table-cell span {
  overflow: hidden;
  display: inline-block;
}
.center-table .ant-table-cell::before {
  display: none !important;
}

@media (max-height: 1024px) {
  .warehouse-table {
    margin: 32px 4% 60px;
  }
}
.ant-input-affix-wrapper {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  height: 48px;
  border-right: none !important;
}
.ant-input-affix-wrapper .ant-input::placeholder {
  color: #12121266;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
}
.ant-input-search-button {
  border-left: none;
  height: 48px !important;
  background-color: white;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.ant-input-search-button svg {
  width: 20px;
  height: 20px;
}
.filter-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: #121212cc;
  border: 1px solid #1212121a;
  width: 123px;
  height: 48px;
  border-radius: 10px;
  padding: 16px 28px 16px 28px;
  cursor: pointer;
}
.add-new-btn {
  min-width: 160px;
  height: 48px;
  padding: 16px 28px 16px 28px;
  border-radius: 10px;
  border: 1px solid #00a8c0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  cursor: pointer;
  color: #121212cc;
  transition: 300ms;
}
.add-new-btn:hover {
  background-color: #00a8c0;
  color: white;
}
.add-new-btn:hover svg path {
  fill: white !important;
}
.warhouse-par {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 41px;
  column-gap: 69px;
  padding: 0 80px 60px;
}
.cart-item {
  height: 425px;
}
.cart-item h4 {
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.03em;
  margin: 20px 0 10px;
}
.cart-item h6 {
  font-family: Archivo;
  font-size: 17px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: -0.03px;
}
@media (max-width: 1024px) {
  .warhouse-par {
    justify-content: center;
    padding: 0 4%;
  }
}

.qty-btn {
  width: 100%;
  height: 63px;
  padding: 10px 26px 10px 26px;
  border-radius: 6px;
  background-color: #006286;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: #eff4f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 11px;
  cursor: pointer;
}
.sub-link {
  position: relative;
  top: 12.5px;
  cursor: pointer;
  color: #fff;
}
.color-bright {
  opacity: 0.4;
}
.add-item {
  border: 1px solid #00a8c0 !important;
  width: 153px !important;
  height: 54px !important;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #00a8c0;
  background-color: transparent !important;
}
.add-item:hover {
  color: #00a8c0 !important;
}
.ant-form-item .ant-form-item-explain-error {
  font-size: 12px;
}
.ant-input-outlined {
  color: #b0b0b0;
}
.ant-input-outlined:focus,
.ant-input-outlined:hover {
  border-color: inherit;
}
.next-assign {
  width: 454px;
  height: 67px;
  padding: 16px 32px 16px 32px;
  border-radius: 6px;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00a8c0;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  color: #eff4f8;
  cursor: pointer;
}
.next-assign:hover {
  color: white !important;
}
.back-assign {
  width: 220px;
  height: 67px;
  padding: 16px 32px 16px 32px;
  border-radius: 6px;
  background-color: #ededed;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  color: #a4a4a4;
  cursor: pointer;
}
.selector-parent {
  display: flex;
  gap: 9px;
  flex-wrap: wrap;
}
.selector-parent .ant-select-selection-placeholder {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  color: #1e1e1e;
}
.selector-parent .ant-select-arrow {
  color: #1e1e1e;
}
.cat-btns-par {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  margin: 0 0 42px;
}
@media (max-width: 768px) {
  .cat-btns-par {
    justify-content: center;
  }
  .cat-btns-par .cat-btn {
    width: 100% !important;
  }
}

.ant-upload {
  width: 100%;
}
.table-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ant-picker {
  width: 100%;
  height: 50px;
  background-color: #d9d9d9;
}
.ant-select-in-form-item {
  height: 61.49px;
  background-color: #e3e3e3;
}
.dash-btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.ant-upload-wrapper {
  position: relative;
  bottom: 8px;
  margin-top: 8px;
}
.close-drop-down {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 400;
  background: rgba(0, 0, 0, 0);
}
.ant-input-outlined {
  color: rgba(0, 0, 0, 0.88);
  height: 61.3px;
  background-color: #e3e3e3;
}
.ant-form-item-control-input {
  margin-bottom: 10px;
}
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #e3e3e3;
}
.ant-input-affix-wrapper {
  height: 48px !important;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  display: block;
}
.apexcharts-legend-text {
  display: flex;
  justify-content: space-between;
  margin: 5px 0px;
}
.ant-input-outlined,
.ant-select-selector {
  background-color: #f7f7f7 !important;
}
.ant-btn-primary {
  background-color: #007f92 !important;
}
.ant-progress .ant-progress-text {
  color: #455560;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
.ant-progress.ant-progress-show-info .ant-progress-outer {
  width: 110px !important;
}
.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td {
  color: #7e8299;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  width: 200px;
  border-bottom: 1px solid #e1e3ea !important;
}
.ant-table-wrapper .ant-table-tbody > tr:last-child > td {
  border-bottom: none !important;
}

.ant-table-wrapper .ant-table-thead > tr > td {
  color: #7e8299;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}
.ant-table-wrapper .ant-table-tbody > tr > td:first-child {
  color: #1e1e1e;
}
.ant-table-wrapper table {
  border: 1px solid #ececec;
  padding: 30px 23px 15px 23px;
  margin-bottom: 30px;
}
.ant-select {
  height: 48px;
  width: 137px;
}
.filter-btns-par div {
  background-color: #fff !important;
}
.filter-btns-par .ant-select-selection-item {
  color: rgba(18, 18, 18, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
}
.warehouse-table-par {
  margin-top: 39px;
  border-radius: 6px;
  padding: 38px 20px 23px 20px;
  border: 1px solid #efefef;
}
.ware-house-item {
  display: flex;
  align-items: center;
}
.ware-house-item h4 {
  color: #1e1e1e;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin-left: 10px;
}
.ware-house-item h6 {
  color: #7e8299;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin-left: 40%;
}
.warehouse-table-par {
  display: grid;
  gap: 27px;
}
.warehouse-table-head {
  display: flex;
  gap: 40%;
}
.warehouse-table-head h4 {
  color: #a1a5b7;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
}
.table {
  margin: 0px 7%;
}
.ant-table-wrapper table {
  border-radius: 6px !important;
}
.company-users-title {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 20.4px;
  letter-spacing: -0.34px;
  text-transform: capitalize;
}
.contract {
  color: #00a8c0;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-decoration-line: underline;
  cursor: pointer;
}
.ant-table-wrapper table {
  padding: 20px 20px 5px 20px;
}
.ant-input-affix-wrapper {
  background-color: #fff !important;
}
.select-category-btn {
  background-color: #fff !important;
}
.ant-picker {
  background-color: #f7f7f7 !important;
}
.image-card-details {
  border-radius: 12px;
  background: var(--white-dark-white, #fff);
  box-shadow: 0px 8px 14px 0px rgba(15, 42, 81, 0.04);
}
.ant-popover {
  z-index: 40;
}
.ant-popover-inner {
  background: #ececec !important;
}
.ant-pagination-options {
  display: none !important;
}
.annoucement .anticon-ellipsis {
  height: 40px;
}
.ant-popover-inner-content {
  cursor: pointer;
}
.model-container .ant-btn-primary > * {
  color: white;
}
.input-message input {
  border: 0;
  background-color: #fff !important;
  border-radius: 0;
  height: 44px;
  border: 1px solid #eee;
}
.input-message input:active {
  border: 0;
  box-shadow: none;
  outline: none;
}
.timeline-par .ant-card-body {
  box-shadow: 0px 8px 14px 0px rgba(15, 42, 81, 0.04);
  overflow-y: auto;
  overflow-x: hidden;
}
.assign-service .ant-card {
  border: 1px solid var(--Gray-Gray-200, #f1f1f2);
}
.assign-service .ant-card-body {
  border-radius: 12px;
  background: var(--white-dark-white, #fff);

  box-shadow: 0px 8px 14px 0px rgba(15, 42, 81, 0.04);
}
.assign-service .ant-table-wrapper table {
  border: 0 !important;
}
.assign-service .ant-table-measure-row {
  display: none;
}
.assign-service .ant-table-thead {
  display: none;
}
.assign-service .ant-collapse-header-text {
  margin-bottom: 30px;
}

.table-row-tracking {
  flex-wrap: nowrap;
}
.order-track-table .ant-table-content {
  overflow: auto;
}
.order-track-table table {
  margin-bottom: 0;
}
.card-icon {
  position: absolute;
  right: 30px;
}
.notifications {
  position: relative;
  cursor: pointer;
}
.total-notifications {
  background-color: #ff6252;
  width: 16px;
  height: 16px;
  color: #fff;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: -0.1px;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  right: -8px;
  top: -6px;
}
.notefication-alert {
  z-index: 10000;
  position: absolute;
  top: 119px;
  right: 8%;
  display: grid;
  width: 446px;
  border: 1px solid #ebebeb;
  border-bottom: 0;
}
@media (max-width: 556px) {
  .notefication-alert {
    right: 0;
    width: 100%;
  }
}
.notefication-par {
  display: flex;
  gap: 26px;
  border-bottom: 1px solid #ebebeb;
  padding: 22px 20px;
  z-index: 2;
}
.notefication-par h4 {
  color: #1d252d;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  margin: 0 0 6px 0;
}
.notefication-par h5 {
  color: #1d252d;
  font-family: Montserrat;
  font-size: 11px;
  font-style: italic;
  font-weight: 300;
  line-height: 20px;
  margin: 0 0 12.5px 0;
}
.notefication-par h6 {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 11.717px;
  margin: 0;
  width: 89px;
  height: 29px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}
.view-all-note {
  color: rgba(29, 37, 45, 0.7);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
  border-bottom: 1px solid #ebebeb;
  background: #f2f2f2;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.view-all-note:hover {
  text-decoration-line: underline;
  color: #3b444b;
}
.notefication-alert .grey-bg {
  background: rgba(0, 0, 0, 0.18);
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  cursor: pointer;
}
.notification-page {
  display: grid;
  gap: 13px;
  margin-top: 79px;
  margin-bottom: 40px;
}
.notification-page .notefication-par {
  border-radius: 11px;
  padding: 26px 20px;
  border: 1px solid #ebebeb;
  position: relative;
}
.notification-page .notefication-par .dots {
  position: absolute;
  right: 18px;
  top: 23px;
  cursor: pointer;
}
.add-service-container {
  width: 700px !important;
}
.complaints-colors .apexcharts-series path:nth-child(1) {
  fill: #34aff6cc;
}
.complaints-colors .apexcharts-series .apexcharts-bar-area:nth-child(2) {
  fill: #ff5c3acc;
}
.complaints-colors .apexcharts-series .apexcharts-bar-area:nth-child(3) {
  fill: #ffa200cc;
}
.invoices-colors .apexcharts-radial-series .apexcharts-radialbar-area {
  stroke: #00a8c0;
}
.invoices-colors .apexcharts-datalabel-value {
  color: #3f4254;
  font-size: 20px;
  font-weight: 600;
}
.invoices-colors .apexcharts-text:first-child {
  fill: #7e8299;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}
