.image-at-table {
  width: 37.853px;
  height: 39px;
  margin-right: 5px;
  object-fit: contain;
  border-radius: 3px;
}
.ant-popconfirm-buttons {
  display: flex;
  padding-right: 5px;
}
.cancel-button {
  background-color: #ededed;
  width: 30%;
  font-weight: 600;
  color: #a4a4a4;
  font-family: Inter;
  height: 61.3px !important;
  margin-right: 5px;
}
.arrow-okbtn {
  position: relative;
  right: 3px;
  font-family: Inter;
  top: 5px;
}
.footer-buttons-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}
.input-style {
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 5px;
  height: 61.492px;
}
.header-value-container {
  font-family: Inter;
  color: #868686;
  margin: 0px 0 29px;
}
.header-title-container {
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 24px;
}
.ok-footer-btn {
  width: 100%;
  font-weight: 600;
  height: 61.3px;
}
.mb-10 {
  margin-bottom: 10px;
}
.services-model {
  width: fit-content;
}
.spain-container {
  position: absolute;
  top: 50%;
  left: 50%;
}
.margin-base-y {
  margin: 20px 0px;
}

.card-cordinator .ant-card-body {
  padding: 0 24px 32px;
  height: 430px;
}
.status-complain {
  border-radius: 4px;
  display: flex;
  width: 153px;
  height: 45px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
}
